@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  width: 100%;
  overflow-x: hidden;
}

.swiper {
  width: 100%;
  height: 100%;
  overflow: visible !important;
}

.hero-banner .swiper-slide {
  text-align: center;
  padding: 10px;
  height: 100% !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.applied .swiper-slide {
  text-align: center;
  padding: 10px;
  height: 100% !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-pagination-bullets {
  display: none;
}

.overflow-hidden {
  /* max-height: 0; */
  transition: max-height 0.5s ease-in-out, padding 0.5s ease-in-out;
}

.tab-link {
  transition: color 1s, border-color 1s;
}

.tab-link-active {
  color: #03215c; /* Blue color for active tab */
  border-color: #03215c;
}

.tab-link-inactive {
  color: #949494; /* Default color for inactive tab */
  border-color: transparent;
}

.tab-link-inactive:hover {
  color: #949494; /* Hover color for inactive tab */
  border-color: #949494; /* Hover border color for inactive tab */
}

/* .quickjobs .swiper {
  width: 100%;
  height: 100%;
} */

.quickjobs .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .quickjobs .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
} */

.quickjobs .swiper-wrapper {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 768px) {
  .quickjobs .swiper-wrapper {
    display: flex;
    justify-content: space-between;
    /* gap: 20px; */
  }
}

@media screen and (max-width: 378px) {
  .quickjobs .swiper-wrapper {
    display: flex;
    justify-content: space-between;
    /* gap: 0px; */
  }
}

/* src/index.css or src/App.css */

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

.custom-focus:focus {
  border-color: #3b82f6 !important; /* Tailwind's blue-500 color */
}

.login-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 510px;
  overflow: hidden;
  padding: 30px;
  margin: 0 20px;
  border-radius: 15px;
  z-index: 99;
}
